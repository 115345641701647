import React, { Component, Suspense } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import './App.scss'
import history from './history'

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>
const DefaultLayout = React.lazy(() => import('./views/containers/Layouts'))
const Login = React.lazy(() => import('./views/pages/Login/Login'))

class App extends Component {
  componentDidMount() {
    let tokenKey = localStorage.getItem('TOKEN_KEY')
    let roles = localStorage.getItem('ROLE')
    let path = []
    if (tokenKey === null) {
      history.push('/login')
    }
    if (roles === 'Admin') {
      path = [
        '/konfigurasi/akses_point_wifi',
        '/konfigurasi/jam_kerja',
        '/konfigurasi/jam_kerja_pegawai',
        '/konfigurasi/kalender_tahunan',
        '/konfigurasi/setting_dan_konfigurasi',
        '/konfigurasi/validasi_gps',
        '/konfigurasi/help_manual',
        '/statistik/pengguna',
        '/statistik/absen_masuk',
        '/statistik/absen_pulang',
        '/statistik/eselon',
        '/kepegawaian/organisasi_daerah_opd',
        '/kepegawaian/eselon',
        '/kepegawaian/golongan_dan_ruang',
        '/kepegawaian/daftar_jabatan',
        '/kepegawaian/mutasi',
        '/kepegawaian/riwayat_jabatan',
        '/kepegawaian/pencarian_pegawai',
        '/skp/laporan_pegawai',
        '/skp/kegiatan',
        '/skp/tupoksi',
        '/skp/review_360',
        '/jafung',
        '/pengaturan/pengaturan_umum',
        '/pengaturan/users',
        '/pengaturan/komponen',
        '/konfigurasi/akses_point_wifi/',
        '/konfigurasi/jam_kerja/',
        '/konfigurasi/jam_kerja_pegawai/',
        '/konfigurasi/kalender_tahunan/',
        '/konfigurasi/setting_dan_konfigurasi/',
        '/konfigurasi/validasi_gps/',
        '/konfigurasi/help_manual/',
        '/statistik/pengguna/',
        '/statistik/absen_masuk/',
        '/statistik/absen_pulang/',
        '/statistik/eselon/',
        '/kepegawaian/organisasi_daerah_opd/',
        '/kepegawaian/eselon/',
        '/kepegawaian/golongan_dan_ruang/',
        '/kepegawaian/daftar_jabatan/',
        '/kepegawaian/mutasi/',
        '/kepegawaian/riwayat_jabatan/',
        '/kepegawaian/pencarian_pegawai/',
        '/skp/laporan_pegawai/',
        '/skp/kegiatan/',
        '/skp/tupoksi/',
        '/skp/review_360/',
        '/jafung/',
        '/pengaturan/pengaturan_umum/',
        '/pengaturan/users/',
        '/pengaturan/komponen/',
        '/panduan/panduan_umum/',
        '/panduan/eror_gps/',
        '/panduan/ganti_device/',

      ]
    }
    if (path.includes(window.location.pathname)) {
      history.push('/')
    }
  }

  render() {
    return (
      <Router history={history}>
        <Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
          </Switch>
        </Suspense>
      </Router>
    )
  }
}

export default App
